import React, { useState } from "react";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/read-more.module.css";
  
const ReadMore = ({ children }) => {
  const text = children;
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  }
  return (
    <div className={styles.root}>
      <div className={cn(styles.hiddenContent, isExpanded && styles.active)}>{text}</div>
      <button onClick={toggleReadMore} className={styles.toggle}>{isExpanded ? "Read Less" : "Read More"}</button>
    </div>
  )
};
  
export default ReadMore;
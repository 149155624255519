import React from "react";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import TeamBio from "../components/team-bio";
import TeamList from "../components/team-list";

import * as styles from "../styles/components/team-content.module.css";

function Content({ content }) {
  return (
    <div className={styles.root} id="content">
      {content.map(({ _key, content, team, detailed }) => {
        return (
          <div className={styles.contentRow} key={_key}>
            <div className="grid">
              <div className="col-12 col-md-6">
                <div className="content-sm">
                  {content.heading && <h2 className={cn("h1", styles.heading)}>{content.heading}</h2>}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="content-lg">
                  {content._rawContent && <PortableText blocks={content._rawContent} />}
                </div>
              </div>
            </div>
            {detailed ? (
              <TeamBio team={team} />
            ) : (
              <TeamList team={team} />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Content;

import React, { useLayoutEffect } from "react";
import Container from "./container";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import PageHeader from "../components/page-header";
import Content from "../components/team-content";

import * as styles from "../styles/components/team-main.module.css";

const TeamMain = (props) => {

  const {
    pageHeader,
    content
  } = props;

  gsap.registerPlugin(ScrollTrigger);
  const tl = gsap.timeline();

  useLayoutEffect(() => {
    setTimeout(() => {
      tl.to('#team-bg', {
        opacity: 1,
        ease: "none",
      });

      tl.to('#team-bg', {
        scrollTrigger: {
          trigger: 'body',
          start: "top bottom", // the default values
          end: "bottom top",
          scrub: true
        },
        yPercent: 150,
        ease: 'none',
      });
    }, 500);
  }, []);

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.bg} id="team-bg">
          <div className="m-hide">
            <img src="/images/feature_man-light.png" alt="" />
          </div>
          <div className="d-hide">
            <img src="/images/feature_woman-light.png" alt="" />
          </div>
        </div>

        {pageHeader && (<PageHeader {...pageHeader} />)}

        {content && (<Content content={content} />)}
      </Container>
    </div>
  );
}

export default TeamMain;

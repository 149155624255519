import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import TeamMain from "../components/team-main";
import CallToAction from "../components/call-to-action";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query TeamPageQuery {
    team: sanityTeamPage {
      pageHeader {
        title
        description
      }
      teamSections {
        _key
        content {
          heading
          _rawContent
        }
        team {
          _key
          team {
            image {
              ...SanityImage
              alt
            }
            name
            title
            _rawContent
            disableReadMore
          }
        }
        detailed
      }
      callToAction {
        title
        button {
          blank
          noArrow
          primaryButton
          text
          url
        }
      }
    }
  }
`;

const TeamPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const team = (data || {}).team;

  return (
    <Layout>
      <Seo
        title={team.pageHeader.title}
        description={team.pageHeader.description}
      />

      {(team.pageHeader || (team.teamSections && team.teamSections.length)) ? <TeamMain pageHeader={team.pageHeader} content={team.teamSections} /> : null}

      {team.callToAction && <CallToAction {...team.callToAction} />}
    </Layout>
  );
};

export default TeamPage;

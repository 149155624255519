import React from "react";
import { cn } from "../lib/helpers";
import ReadMore from '../components/read-more';
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/team-bio.module.css";

function TeamBio({ team }) {
  return (
    <div className={styles.root}>
      {team.map(({ _key, team }) => {
        return (
          <div className={cn("grid", styles.contentRow)} key={_key}>
            <div className="col-12 col-md-6">
              {team.image && team.image.asset && (
                <div className={styles.image}>
                  <img
                    src={imageUrlFor(buildImageObj(team.image))
                      .auto("format")
                      .url()}
                    alt={team.name}
                  />
                </div>
              )}
            </div>
            <div className="col-12 col-md-6">
              <div className="content-sm">
                <div className={styles.personInfo}>
                  {team.name && (<div className={styles.name}>{team.name}</div>)}
                  {team.title && (<div className={styles.title}>{team.title}</div>)}
                </div>
                {team._rawContent && (
                  <>
                  {team.disableReadMore ? (
                    <PortableText blocks={team._rawContent} />
                  ) : (
                    <ReadMore>
                      <PortableText blocks={team._rawContent} />
                    </ReadMore>
                  )}
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TeamBio;

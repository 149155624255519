import React from "react";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/team-list.module.css";

function TeamList({ team }) {
  return (
    <div className={cn("grid justify-md-end", styles.root)}>
      {team.map(({ _key, team }) => {
        return (
          <div className={cn("col-6 col-md-3", styles.item)} key={_key}>
            {team.image && team.image.asset && (
              <div className={styles.image}>
                <img
                  src={imageUrlFor(buildImageObj(team.image))
                    .auto("format")
                    .url()}
                  alt={team.name}
                />
              </div>
            )}
            {team.name && (<div className={styles.name}>{team.name}</div>)}
            {team.title && (<div className={styles.title}>{team.title}</div>)}
          </div>
        );
      })}
    </div>
  );
}

export default TeamList;
